<template>
  <div>
    <Header
      :uniqueYears="uniqueYears"
      :uniqueArtists="uniqueArtists"
      :selectedYear="filterState.selectedYear"
      :selectedArtist="filterState.selectedArtist"
      @year-change="handleYearChange"
      @artist-change="handleArtistChange"
    />
    
    <div class="container mx-auto px-4 py-8">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        <WatchCard
          v-for="watch in visibleWatches"
          :key="watch.id"
          :id="watch.id"
          :image="watch.image"
          :model="watch.model"
          :artist="watch.artist"
          :year="watch.year"
          @click="viewWatchDetail(watch)"
        />
      </div>

      <div v-if="loading" class="text-center py-4">Loading more watches...</div>
      <div v-if="!loading && noMoreData" class="text-center py-4 text-gray-500">No more watches to load.</div>
    </div>

    <button
    @click="scrollToTop"
    class="fixed bottom-4 right-4 p-3 bg-black text-white rounded-full hover:bg-gray-800 shadow-lg focus:outline-none transition-opacity duration-300"
    :class="{ 'opacity-0': !showBackToTop, 'opacity-100': showBackToTop }"
  >
    ↑ Back to Top
  </button>


    <WatchDetailModal
      :isVisible="modalVisible"
      :watch="selectedWatch"
      @close="modalVisible = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Header from "@/components/SiteHeader.vue";
import WatchCard from "@/components/WatchCard.vue";
import WatchDetailModal from "@/components/WatchDetailModal.vue";
import { debounce } from "@/utils/debounce";

export default {
  components: {
    Header,
    WatchCard,
    WatchDetailModal
  },
  data() {
    return {
      itemsPerPage: 9,
      loading: false,
      noMoreData: false,
      showBackToTop: false,
      modalVisible: false,
      selectedWatch: null,
      debouncedFilterWatches: debounce(function () {
        this.visibleWatches; // Trigger recomputation
      }, 300),
    };
  },
  computed: {
    ...mapState(['watches']),
    ...mapGetters(['filterState']),
    uniqueYears() {
      const years = this.watches.map(watch => watch.year);
      return [...new Set(years)].sort();
    },
    uniqueArtists() {
      const artists = this.watches.map(watch => watch.artist);
      return [...new Set(artists)].sort((a, b) => a.localeCompare(b));
    },
    filteredWatches() {
      return this.watches.filter(watch => {
        const matchesYear = !this.filterState.selectedYear || watch.year === this.filterState.selectedYear;
        const matchesArtist = !this.filterState.selectedArtist || watch.artist === this.filterState.selectedArtist;
        return matchesYear && matchesArtist;
      });
    },
    visibleWatches() {
      const start = (this.filterState.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredWatches.slice(0, end);
    }
  },
  mounted() {
    this.$store.dispatch('fetchWatches');
    this.applyFilters();
    this.debouncedHandleScroll = debounce(this.handleDebouncedScroll, 300);
    window.addEventListener('scroll', this.handleScroll);  // Add the non-debounced scroll listener
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['setFilterState']),
    applyFilters() {
      this.debouncedFilterWatches();
    },
    async loadInitialWatches() {
      this.loading = true;
      this.noMoreData = this.filteredWatches.length <= this.itemsPerPage;
      this.loading = false;
    },
    async loadMoreWatches() {
      if (this.loading || this.noMoreData) return;

      this.loading = true;

      await new Promise(resolve => setTimeout(resolve, 200));

      const start = this.filterState.currentPage * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      if (this.filteredWatches.length <= end) {
        this.noMoreData = true;
      }

      this.$store.dispatch('setFilterState', {
        ...this.filterState,
        currentPage: this.filterState.currentPage + 1
      });

      this.loading = false;
    },
    handleFilterChange() {
      this.$store.dispatch('setFilterState', {
        ...this.filterState,
        currentPage: 1
      });
      this.noMoreData = false;
      this.loadInitialWatches();
    },
    handleYearChange(selectedYear) {
      this.$store.dispatch('setFilterState', {
        selectedYear,
        selectedArtist: this.filterState.selectedArtist,
        currentPage: 1
      });
      this.handleFilterChange();
    },
    handleArtistChange(selectedArtist) {
      this.$store.dispatch('setFilterState', {
        selectedYear: this.filterState.selectedYear,
        selectedArtist,
        currentPage: 1
      });
      this.handleFilterChange();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.toggleBackToTopButton(); // Manage back-to-top visibility
      this.debouncedHandleScroll();  // Debounced handling for loading more watches
    },
    toggleBackToTopButton() {
      if (window.scrollY > 300) {
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }
    },
    handleDebouncedScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 1000) {
        this.loadMoreWatches();
      }
    },
    viewWatchDetail(watch) {
      this.selectedWatch = watch;
      this.modalVisible = true;
    }
  }
}
</script>
