<template>
  <div
    class="py-8 h-auto bg-white rounded-lg shadow-md overflow-hidden flex flex-col justify-around"
    @click="$emit('click', id)"
  >
    <img :src="image" alt="Watch Image" class="w-full h-80 object-contain">
    <div class="p-4">
      <p class="text-xl text-center font-bold text-[#4A5568]">{{ model }}</p>
      <h3 class="text-lg text-center  text-[#4A5568]">{{ artist }}</h3>
      <p class="text-base text-center text-[#4A5568]">{{ year }}</p>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    image: String,
    model: String,
    artist: String,
    year: Number,
    id: String
  }
};
</script>
