import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    watches: [],
    filterState: {
      selectedYear: '',
      selectedArtist: '',
      currentPage: 1
    },
    scrollPosition: 0 // Add this line
  },
  mutations: {
    SET_WATCHES(state, watches) {
      state.watches = watches;
    },
    SET_FILTER_STATE(state, payload) {
      state.filterState.selectedYear = payload.selectedYear || '';
      state.filterState.selectedArtist = payload.selectedArtist || '';
      state.filterState.currentPage = payload.currentPage || 1;
    },
    SET_SCROLL_POSITION(state, position) {
      state.scrollPosition = position;
    }
  },
  actions: {
    setFilterState({ commit }, payload) {
      commit('SET_FILTER_STATE', payload);
    },
    setScrollPosition({ commit }, position) {
      commit('SET_SCROLL_POSITION', position);
    },
    async fetchWatches({ commit }) {
      try {
        const response = await axios.get('https://specials.swatch-art-peace-hotel.com/api/watches.php');
        commit('SET_WATCHES', response.data.map(watch => ({
          id: watch.p_code,
          model: watch.p_name,
          image: `https://specials.swatch-art-peace-hotel.com/uploads/${watch.p_code}.jpg`,
          artist: watch.p_type,
          year: watch.p_year
        })));
      } catch (error) {
        console.error("Error fetching watches:", error);
      }
    },
  },
  getters: {
    filterState(state) {
      return state.filterState;
    },
    watches(state) {
      return state.watches;
    },
    scrollPosition(state) {
      return state.scrollPosition;
    }
  }
});
