<template>
  <transition name="fade-slide" @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center top-0">
      <div class="bg-white h-screen w-screen flex flex-col md:flex-row">
        <div class="w-full h-[70vh] md:h-full md:w-6/12 flex justify-center items-center p-8 md:p-0">
          <img :src="watch.image" alt="Watch Image" class="max-h-full object-contain">
        </div>
        <div class="w-full h-[30vh] md:h-full md:w-6/12 flex flex-col justify-center items-center p-8 md:p-0">
          <button @click="close" class="absolute top-0 right-0 mt-6 mr-6 flex justify-center items-center bg-black text-white hover:bg-gray-800 rounded-full w-10 h-10">
            <svg viewBox="0 0 24 24" focusable="false" class="w-4 h-4 leading-4 align-middle shrink-0" aria-hidden="true">
              <path fill="currentColor" d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"></path>
            </svg>
          </button>
          <div class="flex flex-col items-center text-center">
            <h2 class="text-2xl md:text-6xl font-bold leading-tight text-[#1A202C]">{{ watch.model }}</h2>
            <p class="text-xl md:text-2xl text-[#1A202C]">{{ watch.artist }}</p>
            <p class="text-base md:text-xl text-[#1A202C]">{{ watch.year }}</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    watch: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(100%)';
    },
    enter(el, done) {
      el.offsetWidth; // trigger reflow
      el.style.transition = 'opacity 0.3s, transform 0.3s';
      el.style.opacity = 1;
      el.style.transform = 'translateY(0)';
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.3s, transform 0.3s';
      el.style.opacity = 0;
      el.style.transform = 'translateY(100%)'; // Slide back down
      setTimeout(done, 300); // Ensure the transition lasts 300ms before removing the element
    }
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }
}
</script>

<style scoped>
/* Fade and Slide transition styles */
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.fade-slide-enter, .fade-slide-leave-to {
  opacity: 0;
  transform: translateY(100%); /* Start and end position for sliding */
}
</style>
