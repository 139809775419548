<template>
  <div class="px-6 py-6 flex gap-4 flex-col md:flex-row justify-between items-center bg-white shadow-md">
    <img
      :src="logoSrc"
      alt="Watch Catalog Logo"
      class="h-6 w-auto cursor-pointer"
      @click="resetFilters"
    />

    <div class="flex gap-4 flex-col md:flex-row items-center w-full md:w-auto">
      <div class="flex-shrink-0 w-full md:w-40 relative">
        <label for="yearFilter" class="sr-only">Filter by Year:</label>
        <select
          id="yearFilter"
          v-model="localSelectedYear"
          @change="emitYearChange"
          class="py-2 pl-6 border border-gray-300 bg-black text-white rounded-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm md:text-lg w-full appearance-none pr-10"
          :style="{ backgroundImage: `url(${arrowDownUrl})`, backgroundPosition: 'right 1.5rem center', backgroundSize: '1rem', backgroundRepeat: 'no-repeat' }"
        >
          <option value="">All Years</option>
          <option v-for="year in uniqueYears" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>

      <div class="flex-shrink-0 w-full md:w-40 relative">
        <label for="artistFilter" class="sr-only">Filter by Artist:</label>
        <select
          id="artistFilter"
          v-model="localSelectedArtist"
          @change="emitArtistChange"
          class="py-2 pl-6 border border-gray-300 bg-black text-white rounded-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm md:text-lg w-full appearance-none pr-10"
          :style="{ backgroundImage: `url(${arrowDownUrl})`, backgroundPosition: 'right 1.5rem center', backgroundSize: '1rem', backgroundRepeat: 'no-repeat' }"
        >
          <option value="">All Artists</option>
          <option v-for="artist in uniqueArtists" :key="artist" :value="artist">{{ artist }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/swatch-logo.svg";
import arrowDown from "@/assets/arrow-down.svg";

export default {
  name: "SiteHeader",
  props: {
    uniqueYears: {
      type: Array,
      default: () => [],
    },
    uniqueArtists: {
      type: Array,
      default: () => [],
    },
    selectedYear: {
      type: String,
      default: "",
    },
    selectedArtist: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localSelectedYear: this.selectedYear,
      localSelectedArtist: this.selectedArtist,
      arrowDownUrl: arrowDown,
    };
  },
  computed: {
    logoSrc() {
      return Logo;
    },
  },
  methods: {
    emitYearChange() {
      this.$emit("year-change", this.localSelectedYear);
    },
    emitArtistChange() {
      this.$emit("artist-change", this.localSelectedArtist);
    },
    resetFilters() {
      this.localSelectedYear = "";
      this.localSelectedArtist = "";

      this.$emit("year-change", "");
      this.$emit("artist-change", "");

      window.scrollTo(0, 0);
    },
  },
  watch: {
    selectedYear(newValue) {
      this.localSelectedYear = newValue;
    },
    selectedArtist(newValue) {
      this.localSelectedArtist = newValue;
    },
  },
};
</script>