<template>
  <div id="app">
    <Catalog />
  </div>
</template>

<script>
import Catalog from "@/components/WatchCatalog.vue";

export default {
  name: "App",
  components: {
    Catalog
  }
};
</script>
